<template>
  <div>
    <p class="text-2xl mb-6">Correction matching</p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="siteForm">
            <v-row>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="site.site"
                  :items="siteList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Sélectionner le site"
                  dense
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    disabled
                    v-model="site.platform"
                    dense
                    label="Platform"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    disabled
                    v-model="site.identifier"
                    dense
                    label="Identifiant"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    disabled
                    v-model="site.comment"
                    dense
                    label="Commentaire"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveSite"
                >
                  Corriger</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'c2c-admin-sites-matching' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, integerValidator } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";
export default {
  name: "SiteCreateOrUpdate",
  async created() {
    if ("id" in this.$route.params) {
      this.getSiteById(this.$route.params.id);
      this.editing = true;
    } else {
      this.editing = false;
    }
    this.siteList = await this.$store.dispatch("common/getSiteList");
  },
  data() {
    return {
      required,
      integerValidator,
      siteList: [],
      site: {
        pk: -1,
        site: null,
        platform: null,
        identifier: null,
        comment: null,
      },
    };
  },
  methods: {
    async saveSite() {
      const isFormValid = this.$refs.siteForm.validate();
      if (!isFormValid) return;

      const { status } = await axios.put(`/platform-sites/${this.site.pk}`, {
        site_id: this.site.site,
      });
      if (status === 200) {
        await router.push({ name: "c2c-admin-sites-matching" });
      }
    },
    async getSiteById(id) {
      const { status, data } = await axios.get(`/platform-sites/${id}`);
      if (status === 200) {
        this.site = {
          pk: data.id,
          platform: data.platform,
          identifier: data.identifier,
          comment: data.comment,
        };
      }
    },
    normalize(s) {
      return s
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-z0-9 -]/gi, "")
        .replace(/[\s.*]/gi, "");
    },
  },
  watch: {
    name(v) {
      this.code = this.normalize(v);
      this.shortname = this.normalize(v)
        .replace(/[aeiou]/gi, "")
        .substring(0, 5);
    },
  },
};
</script>

<style scoped></style>
